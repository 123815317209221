import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { ReactElement } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { QueryData } from './posts.types';
import './posts.scss';

export const CategoryPosts = (): ReactElement => {
  const data: QueryData = useStaticQuery(graphql`
    query {
      allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
        nodes {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `);

  const posts = data.allMdx.nodes;

  if (posts.length === 0) {
    return (
      <p>
        No blog posts found. Add markdown posts to &quot;content/blog&quot; (or
        the directory you specified for the &quot;gatsby-source-filesystem&quot;
        plugin in gatsby-config.js).
      </p>
    );
  }
  return (
    <div className="posts-wrapper">
      {posts.map(post => {
        const image = getImage(post.frontmatter.image) as IGatsbyImageData;
        const title = post.frontmatter.title || post.fields.slug;
        return (
          <Link to={post.fields.slug} key={post.fields.slug}>
            <article
              className="post-wrapper"
              itemScope
              itemType="http://schema.org/Article"
            >
              <GatsbyImage alt="post" className="post-image" image={image} />
              <article className="post-text-wrapper">
                <header className="post-header">
                  <h3>
                    <span itemProp="headline">{title}</span>
                  </h3>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p className="post-description">
                    {post.frontmatter.description || post.excerpt}
                  </p>
                </section>
              </article>
            </article>
          </Link>
        );
      })}
    </div>
  );
};
