import React from 'react';

import { CategoryPosts } from '../components/posts/posts';
import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';

const BlogIndex = () => {
  return (
    <Layout location="some location" title="Blog">
      <SEO title="All posts" />
      {/* <PostsScroller /> */}
      {/* <CategorySelector /> */}
      <CategoryPosts />
    </Layout>
  );
};

export default BlogIndex;
